import React from 'react';
import { Button } from 'antd';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Router from 'next/router';

const Wrapper = styled.div`
  background-color: #f6f6f6;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error__img {
      width: 90vw;
      height: calc(90vw * 0.54);
      max-width: 700px;
      max-height: calc(700px * 0.54);
      position: relative;
      margin-bottom: 2rem;
      overflow: hidden;

      img {
          height: 185%;
          object-fit: cover;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
      }
    }
  }

  .error__message {
      text-align: center;
      width: 70%;
      margin-bottom 2rem;
  }

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

class Custom404 extends React.PureComponent {
  handleBack = () => {
    Router.back();
  };

  render() {
    return (
      <Wrapper>
        <Helmet title="404 - Page Not Found | 5-Star Telemed" />
        <div className="error__img">
          <img
            src="https://5startelemed.nyc3.digitaloceanspaces.com/production/static/404.png"
            alt="404 page not found"
          />
        </div>
        <p className="error__message">
          Sorry. The page you are trying to visit could not be found.
        </p>
        <Button className="error__btn" onClick={this.handleBack} type="primary" size="large">
          Go Back
        </Button>
      </Wrapper>
    );
  }
}

export default Custom404;
